export type CurrentUser = {
  id: number
  email: string
  full_name: string
}

export type PageData = {
  current_user?: CurrentUser
  fiscal_year_start: number
  js_week_start_day: number
  csrf_param: string
  csrf_token: string
  wants_24h_time: boolean
  calendar_integration: {
    outlook_connected: boolean
    google_connected: boolean
    own_timesheet: boolean
    events_url: string
    calendars_url: string
    google_calendar_connect_url: string
    outlook_calendar_connect_url: string
    google_calendar_disconnect_url: string
    outlook_calendar_disconnect_url: string
    reconnect_url: string
  }
}

export let pageData: PageData = {
  fiscal_year_start: 1,
  js_week_start_day: 1,
  csrf_param: '',
  csrf_token: '',
  wants_24h_time: false,
  calendar_integration: {
    outlook_connected: false,
    google_connected: false,
    own_timesheet: false,
    events_url: '',
    calendars_url: '',
    google_calendar_connect_url: '',
    outlook_calendar_connect_url: '',
    google_calendar_disconnect_url: '',
    outlook_calendar_disconnect_url: '',
    reconnect_url: '',
  },
}

if (typeof document !== 'undefined') {
  const script = document.getElementById('page_data-data-island')
  if (script) pageData = JSON.parse(script.innerHTML)
}
