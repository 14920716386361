import {
  Button,
  Dialog,
  IconArrowRight,
  IconChevronsUp,
} from '@harvesthq/porchlight'
import classnames from 'classnames'
import { pageData } from 'harvest'
import { render } from 'preact'
import { useEffect, useState } from 'preact/compat'

import PremiumCarouselActivityLogImage from '../../assets/images/premium-carousel-activity-log.webp'
import PremiumCarouselActivityLogImage2x from '../../assets/images/premium-carousel-activity-log@2x.webp'
import PremiumCarouselOverviewImage from '../../assets/images/premium-carousel-overview.webp'
import PremiumCarouselOverviewImage2x from '../../assets/images/premium-carousel-overview@2x.webp'
import PremiumCarouselProfitabilityImage from '../../assets/images/premium-carousel-profitability.webp'
import PremiumCarouselProfitabilityImage2x from '../../assets/images/premium-carousel-profitability@2x.webp'
import PremiumCarouselRequiredNotesImage from '../../assets/images/premium-carousel-required-notes.webp'
import PremiumCarouselRequiredNotesImage2x from '../../assets/images/premium-carousel-required-notes@2x.webp'
import PremiumCarouselSAMLImage from '../../assets/images/premium-carousel-saml.webp'
import PremiumCarouselSAMLImage2x from '../../assets/images/premium-carousel-saml@2x.webp'
import tips from '../../assets/javascripts/ext/tips'

const PremiumTrialFlow = () => {
  const { current_user, show_upgrade_premium_trial_dialog } = pageData

  const [showDialog, setShowDialog] = useState(() => {
    return !current_user.dismissed_tip_names.includes('premium-trial-dialog')
  })
  const [tooltipDismissed, setTooltipDismissed] = useState(false)
  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  const steps = [
    {
      title: 'Introducing the all-new Harvest Premium plan',
      description:
        'Premium includes new features like Profitability Reporting, Activity Log, and SAML-based SSO. We’re giving you access to Premium features for a limited time at no extra cost.',
      btn_text: 'Next',
      image_url: PremiumCarouselOverviewImage,
      image_url_2x: PremiumCarouselOverviewImage2x,
      link_text: show_upgrade_premium_trial_dialog ? 'Upgrade now' : null,
      link_url: '/company/billing/subscription/upgrade_plan?plan_key=premium',
      analytics_key: 'intro',
    },
    {
      title: 'Identify what’s driving profit for your business',
      description:
        'Access reports that visualize revenue, costs, and profit margin for every project, client, team, and task. <strong>Visible only to admins.</strong>',
      btn_text: 'Next',
      link_text: 'Check out the Profitability report',
      link_url: '/reports/profitability',
      image_url: PremiumCarouselProfitabilityImage,
      image_url_2x: PremiumCarouselProfitabilityImage2x,
      analytics_key: 'profitability-report',
    },
    {
      title: 'Audit changes made to timesheets and projects',
      description:
        'Stay informed with a clear record of who changed what and when. Spot errors and ensure accurate reporting. <strong>Only Admins can see company-wide activity.</strong>',
      btn_text: 'Next',
      link_text: 'Check out the Activity Log',
      link_url: '/reports/activity',
      image_url: PremiumCarouselActivityLogImage,
      image_url_2x: PremiumCarouselActivityLogImage2x,
      analytics_key: 'activity-log',
    },
    {
      title: 'Get the context you need',
      description:
        'Now you have the option to require notes for every time entry to confirm the right work was tracked to the right task, enabling easy audits and streamlined communication.',
      btn_text: 'Next',
      link_text: 'Update account preferences',
      link_url: '/company/preferences/edit#time-entry-notes',
      image_url: PremiumCarouselRequiredNotesImage,
      image_url_2x: PremiumCarouselRequiredNotesImage2x,
      analytics_key: 'require-notes',
    },
    {
      title: 'Log in with SAML SSO for enhanced security',
      description:
        'Streamline and secure your team’s login experience with SAML-based SSO via Microsoft Entra, Okta, and more (only available upon upgrade).',
      btn_text: 'Tell me more about Premium',
      image_url: PremiumCarouselSAMLImage,
      image_url_2x: PremiumCarouselSAMLImage2x,
      analytics_key: 'saml-sso',
    },
  ]

  const premiumTrialTooltip = document.getElementById(
    'premium-access-btn-tooltip'
  )

  useEffect(() => {
    if (premiumTrialTooltip && !tooltipDismissed && !showDialog) {
      premiumTrialTooltip.hidden = false
    }

    const onClick = (event) => {
      if (event.target.closest('#main-nav-premium-access-btn')) {
        setShowDialog(true)
      } else if (
        event.target.closest('.pds-tooltip-close') &&
        event.target.closest('#premium-access-btn-tooltip')
      ) {
        premiumTrialTooltip.hidden = true
        setTooltipDismissed(true)
      }
    }

    document.addEventListener('click', onClick)

    return () => document.removeEventListener('click', onClick)
  }, [showDialog, setTooltipDismissed, premiumTrialTooltip, tooltipDismissed])

  const {
    title,
    description,
    btn_text,
    link_url,
    link_text,
    image_url,
    image_url_2x,
    analytics_key,
  } = steps[currentStepIndex]

  const handleLinkClick = () => {
    closeDialog()
  }

  const incrementStep = () => {
    if (currentStepIndex == steps.length - 1) {
      closeDialog()
      window.location.href = '/premium'
    } else {
      setCurrentStepIndex(currentStepIndex + 1)
    }
  }

  const toggleTooltip = () => {
    if (premiumTrialTooltip && !tooltipDismissed) {
      premiumTrialTooltip.hidden = false
    }
  }

  const closeDialog = () => {
    setShowDialog(false)
    setCurrentStepIndex(0)
    tips.dismiss('premium-trial-dialog')
    toggleTooltip()
  }

  if (!showDialog) return null

  return (
    <Dialog
      className="premium-trial-dialog"
      size="lg"
      closeIcon
      onClose={() => closeDialog()}
      aria-labelledby="#premium-trial-title"
      aria-describedby="#premium-trial-description"
      future>
      <div className="pds-flex pds-gap-sm pds-my-md">
        <div className="pds-column-7">
          <div className="pds-badge pds-badge-orange-inverse pds-mb-sm pds-display-inline-flex pds-gap-xs pds-items-center">
            <IconChevronsUp width="14" height="14" aria-hidden="true" />
            <span className="pds-weight-semi" aria-label="Premium feature">
              Premium
            </span>
          </div>
          <h2 id="premium-trial-title">{title}</h2>
          <p
            id="premium-trial-description"
            className="pds-mt-sm"
            dangerouslySetInnerHTML={{ __html: description }}></p>
          <Button
            size="sm"
            variant="secondary"
            onClick={incrementStep}
            data-analytics-element-id={`carousel-step-${analytics_key}`}>
            {btn_text}
            {currentStepIndex < steps.length - 1 && <IconArrowRight />}
          </Button>
          {link_text && (
            <div className="pds-mt-md">
              <a
                href={link_url}
                className="pds-link pds-color-white"
                onClick={handleLinkClick}
                data-analytics-element-id={`carousel-link-${analytics_key}`}>
                {link_text}
              </a>
            </div>
          )}
        </div>
        <div className="pds-column-5 carousel-image-container pds-display-flex pds-items-center">
          <img src={image_url} srcSet={`${image_url_2x} 2x`} height="250px" />
        </div>
      </div>

      <div className="pds-flex pds-justify-center dots-container pds-gap-xs pds-mt-md">
        {steps.map((step) => (
          <button
            key={step.title}
            onClick={() => setCurrentStepIndex(steps.indexOf(step))}
            data-analytics-element-id={`carousel-dot-step-${step.analytics_key}`}>
            <div
              className={classnames('dot', {
                active: steps.indexOf(step) == currentStepIndex,
              })}
            />
          </button>
        ))}
      </div>
    </Dialog>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  if (pageData.show_premium_trial_dialog) {
    const div = document.createElement('div')
    div.id = 'premium-trial-flow'
    document.body.appendChild(div)

    render(<PremiumTrialFlow />, div)
  }
})
