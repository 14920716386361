import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/query'
import { csrfToken } from './csrf-token'

type QueryFn = BaseQueryFn<string | FetchArgs, unknown, unknown, {}, {}>

/**
 * Set a header value
 */
const setHeader = (args: FetchArgs, name: string, value: string) => {
  if (!args.headers) {
    args.headers = { [name]: value }
    return
  }

  if (args.headers instanceof Headers) {
    args.headers.set(name, value)
    return
  }

  if (Array.isArray(args.headers)) {
    args.headers.push([name, value])
    return
  }

  args.headers[name] = value
}

/**
 * A custom baseQuery that handles the csrf token and format.
 *
 * https://redux-toolkit.js.org/rtk-query/usage/customizing-queries
 */
export function jsonQuery(results?: Record<string, unknown>): QueryFn {
  const fetchQuery = fetchBaseQuery()

  return function (options, api, extra) {
    // If we have the results from the server, just use those.
    const data = results?.[api.endpoint]
    if (data) {
      delete results[api.endpoint]
      return { data }
    }

    // Options can just be a string sometimes
    if (typeof options === 'string') {
      options = { url: options }
    }

    const { method } = options

    // Only accept JSON responses
    setHeader(options, 'accept', 'application/json')

    // Send JSON unless sending FormData
    if (!(options.body instanceof FormData)) {
      setHeader(options, 'content-type', 'application/json')
    }

    // Add CSRF token for non-get requests
    if (method?.toUpperCase() !== 'GET') {
      setHeader(options, 'x-csrf-token', csrfToken())
    }

    return fetchQuery(options, api, extra)
  }
}
