import { pageData } from './pageData'

const meta = (name: string) => {
  const element = document.querySelector(`meta[name="${name}"]`)
  return element instanceof HTMLMetaElement ? element.content : ''
}

export function csrfParam() {
  return pageData.csrf_param || meta('csrf-param')
}

export function csrfToken() {
  return pageData.csrf_token || meta('csrf-token')
}
