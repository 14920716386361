import { Button, Menu, MenuButton, MenuLink } from '@harvesthq/porchlight'
import { pageData as page_data } from 'harvest'
import { createRoot } from 'preact/compat/client'

import {
  StripePaymentForm,
  StripeRecurringPaymentForm,
} from './ext/stripe_elements_form'

import './ext/print'
import '@harvesthq/porchlight/js/tooltip'
import './ext/toggleform'
import './ext/form_loader'

const PayInvoiceMenu = () => {
  const { available_payment_methods, show_recurring, pay_pal_url } = page_data

  const onePaymentMethod = available_payment_methods.length === 1
  const singularStripePayment =
    available_payment_methods.length === 2 &&
    available_payment_methods
      .sort()
      .every(
        (value, index) => value === ['stripe_ach', 'stripe_elements'][index]
      )

  function PaymentButton({ children, ...rest }) {
    if (onePaymentMethod || singularStripePayment) {
      return (
        <Button variant="primary" {...rest}>
          {children}
        </Button>
      )
    } else {
      return <MenuButton {...rest}>{children}</MenuButton>
    }
  }

  function PaymentLink({ children, ...rest }) {
    if (onePaymentMethod) {
      return (
        <a className="pds-button pds-button-primary" {...rest}>
          {children}
        </a>
      )
    } else {
      return <MenuLink {...rest}>{children}</MenuLink>
    }
  }

  const buttons = (
    <>
      {singularStripePayment && (
        <PaymentButton
          data-showform="stripe-elements-form"
          data-analytics-element-id="client-invoice-pay-with-stripe"
          onClick={() => {
            !show_recurring
              ? initializeStripePaymentForm('card', show_recurring)
              : setPreferredPaymentMethod('card')
          }}>
          Pay invoice
        </PaymentButton>
      )}
      {!singularStripePayment &&
        available_payment_methods.includes('stripe_elements') && (
          <PaymentButton
            data-showform="stripe-elements-form"
            data-analytics-element-id="client-invoice-pay-with-credit-card"
            onClick={() => {
              !show_recurring
                ? initializeStripePaymentForm('card', show_recurring)
                : setPreferredPaymentMethod('card')
            }}>
            {onePaymentMethod ? 'Pay invoice' : 'Pay with credit card'}
          </PaymentButton>
        )}
      {!singularStripePayment &&
        available_payment_methods.includes('stripe_ach') && (
          <PaymentButton
            data-showform="stripe-elements-form"
            id={!show_recurring ? 'linkButton' : ''}
            data-analytics-element-id="client-invoice-pay-with-bank-transfer"
            onClick={() => {
              !show_recurring
                ? initializeStripePaymentForm('us_bank_account', show_recurring)
                : setPreferredPaymentMethod('us_bank_account')
            }}>
            {onePaymentMethod ? 'Pay invoice' : 'Pay with bank transfer (ACH)'}
          </PaymentButton>
        )}
      {available_payment_methods.includes('pay_pal') && (
        <PaymentLink
          href={pay_pal_url}
          data-method="post"
          id="pay-with-paypal-button"
          data-analytics-element-id="client-invoice-pay-with-paypal"
          onTouchStart="">
          {onePaymentMethod ? 'Pay invoice' : 'Pay with PayPal'}
        </PaymentLink>
      )}
    </>
  )

  if (onePaymentMethod || singularStripePayment) {
    return buttons
  } else {
    return (
      <Menu buttonLabel="Pay invoice" position="right">
        {buttons}
      </Menu>
    )
  }
}

const initializeStripePaymentForm = (preferredPaymentMethod, isRecurring) => {
  if (isRecurring) {
    new StripeRecurringPaymentForm({ preferredPaymentMethod }).mount()
  } else {
    new StripePaymentForm({ preferredPaymentMethod }).mount()
  }
}

const setPreferredPaymentMethod = (paymentMethod) => {
  document.querySelector('input[name="preferred_payment_method"]').value =
    paymentMethod
}

const setupStripeElements = function () {
  const stripeElementsForm = document.getElementById('stripe-elements-form')
  if (!stripeElementsForm) return

  const choosePaymentStep =
    stripeElementsForm.querySelector('.js-choose-payment')
  if (!choosePaymentStep) return

  const continueButton = choosePaymentStep.querySelector('.js-continue')

  const confirmPaymentStep = stripeElementsForm.querySelector(
    '.js-confirm-payment'
  )
  const confirmSingleText = confirmPaymentStep.querySelector('.js-text-single')
  const confirmMultipleText =
    confirmPaymentStep.querySelector('.js-text-multiple')
  const recurringPaymentRadioButton = document.getElementById(
    'credit_card_recurring_payment_true'
  )

  stripeElementsForm.addEventListener('change', (e) => {
    if (e.target.name === 'credit_card_recurring_payment') {
      continueButton.disabled = false
    }
  })

  continueButton.addEventListener('click', () => {
    const recurring = recurringPaymentRadioButton.checked
    const preferredPaymentMethod = document.querySelector(
      'input[name="preferred_payment_method"]'
    ).value

    initializeStripePaymentForm(preferredPaymentMethod, recurring)

    confirmMultipleText.hidden = !recurring
    confirmSingleText.hidden = recurring
    choosePaymentStep.hidden = true
    confirmPaymentStep.hidden = false
  })

  confirmPaymentStep
    .querySelector('.js-cancel')
    .addEventListener('click', () => {
      choosePaymentStep.hidden = false
      confirmPaymentStep.hidden = true
    })
}

document.addEventListener('DOMContentLoaded', function () {
  const payMenu = document.getElementById('pay-menu-wrapper')
  if (payMenu) createRoot(payMenu).render(<PayInvoiceMenu />)

  setupStripeElements()
})
