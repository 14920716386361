import dayjs from 'dayjs'

import { pageData } from '../pageData'

const format12h = /^(1[012]|0?[1-9]):?([0-5]?\d)?\s?(am|pm|a|p)?$/i
const format24h = /^(2[0-4]|1\d|0?\d):?([0-5]?\d)?$/

export const now = () => {
  const now = new Date()
  now.setSeconds(0)
  now.setMilliseconds(0)
  return now
}

export const toDate = (
  dateString: string | null,
  relatedDate: dayjs.Dayjs | null = null
) => {
  if (dateString == null) {
    return null
  }
  dateString = (dateString || '').trim()
  if (dateString === '.') {
    return now()
  }
  dateString = insertColon(dateString)

  const match = dateString?.match(format24h) || dateString?.match(format12h)

  if (!match) {
    return null
  }
  let hours = match[1]
  if (hours === '24') {
    hours = '00'
  }
  const minutes = match[2] || '00'

  let meridian =
    pageData.wants_24h_time || parseInt(hours, 10) > 12
      ? match[3] || ''
      : match[3] || meridianFrom(hours, relatedDate)

  meridian = meridian.toUpperCase()
  if (meridian === 'A' || meridian === 'P') {
    meridian += 'M'
  }

  return new Date(
    `${dayjs().format('MMMM DD, YYYY')} ${hours}:${minutes}:00 ${meridian}`
  )
}

export const meridianFrom = (
  hour: string | number,
  relatedDate: string | number | dayjs.Dayjs | null | undefined
) => {
  const hasLeadingZero = typeof hour === 'string' && hour.trim()[0] === '0'
  hour = parseInt(hour.toString(), 10)
  if (relatedDate != null) {
    if (hour === 0) {
      hour = 12
    }
    const relatedMeridian = dayjs(relatedDate).format('A')
    const relatedHour = parseInt(dayjs(relatedDate).format('hh'), 10)
    hour = hour === 12 && relatedHour !== 12 ? 0 : hour
    switch (false) {
      case !(hour >= relatedHour):
        return relatedMeridian
      case relatedHour !== 12:
        return relatedMeridian
      default:
        return inverseMeridian(relatedMeridian)
    }
  } else {
    const current24Hour = dayjs().hour()
    const currentMeridian = current24Hour > 11 ? 'PM' : 'AM'
    const currentHour = current24Hour % 12
    if (hasLeadingZero && 0 <= hour && hour <= 9) {
      return 'AM'
    } else if (hour > 12) {
      return 'PM'
    } else if (
      hour % 12 > currentHour + 1 ||
      (hour === 12 && currentHour === 11)
    ) {
      return inverseMeridian(currentMeridian)
    } else {
      return currentMeridian
    }
  }
}

const inverseMeridian = (meridian: string) => {
  if (meridian.match(/a/i)) {
    return 'PM'
  } else {
    return 'AM'
  }
}
// WARNING: do not use the default argument here for anything that
// gets saved to the DB. The server's time reference system is
// probably different than the one available on the client. Avoid all
// JavaScript calls to 'Date' if possible.
export const toString = (date = new Date()) => {
  const format = pageData.wants_24h_time ? 'H:mm' : 'h:mma'
  return dayjs(date).format(format)
}

const insertColon = (dateString: string) => {
  dateString = dateString.replace(/\.|,/, ':')
  if (dateString.indexOf(':') !== -1) {
    return dateString
  }
  const match = dateString.match(/(\d+)(am|pm|a|p)?/i)
  if (!match) {
    return ''
  }
  let numeric = match[1] || ''
  if (numeric.length < 3) {
    numeric = numeric + '00'
  }
  const meridian = match[2] || ''
  const colonPosition = numeric.length - 2
  return (
    numeric.slice(0, colonPosition) +
    ':' +
    numeric.slice(colonPosition, numeric.length) +
    meridian
  )
}

export const isValidTime = (time: string) => {
  if (time === '.') {
    return true
  }

  const formats = [
    'hmmA',
    'ha',
    'HH:mma',
    'H:mm',
    'hmmA',
    'HH',
    'HH:mm',
    'HHmm',
    'HH.mm',
    'h.ma',
    'hhA',
    'hha',
    'hA',
    'h:mmA',
    'h:mm a',
    'Hmm',
    'HmmA',
    'Hmm a',
    'hmma',
    'hmm a',
    'h,mm',
    'h.mm',
  ]

  const isValidStrictMode = dayjs(time, formats, true).isValid()

  if (!isValidStrictMode) {
    const match = time.match(format24h) || time.match(format12h)

    if (!match) {
      return false
    }
    return dayjs(time, formats).isValid()
  }

  return isValidStrictMode
}
