import { Toast, ToastVariant } from '@harvesthq/porchlight'
import {
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'preact/compat'
import { createRoot } from 'preact/compat/client'
import type { VNode } from 'preact'

const ref = createRef()

if (typeof document !== 'undefined') {
  document.addEventListener('DOMContentLoaded', () => {
    const element = document.getElementById('toast')
    if (!element) return
    createRoot(element).render(<ToastContainer ref={ref} />)
  })
}

export type ToastContent = string | VNode | (() => string | VNode)

export type ShowToastOptions = {
  content: ToastContent
  duration?: number
  variant?: ToastVariant
}

export type PartialShowOptions = Omit<ShowToastOptions, 'content'>

export type ToastRef = {
  show: (options: ShowToastOptions) => void
}

const ToastContainer = forwardRef<ToastRef>(function ToastContainer(
  _props,
  ref
) {
  const wrapper = useRef<HTMLDivElement | null>(null)
  const lastId = useRef(0n)
  const [content, setContent] = useState<ToastContent>('')
  const [hidden, setHidden] = useState<boolean>(true)
  const [duration, setDuration] = useState<number>(12000)
  const [variant, setVariant] = useState<ToastVariant>('notice')

  useImperativeHandle(ref, () => ({
    show({ content, duration = 12000, variant = 'notice' }) {
      setHidden(false)
      setDuration(duration)
      setVariant(variant)
      setContent(content)
      const id = ++lastId.current
      return {
        hide() {
          if (id !== lastId.current) return
          setHidden(true)
        },
      }
    },
  }))

  async function hide() {
    const { current } = wrapper
    if (!current) return

    const keyframes = [{ opacity: 1 }, { opacity: 0 }]
    const options = {
      duration: 200,
      iterations: 1,
    }
    await current.animate(keyframes, options).finished
    current.hidden = true
    setHidden(true)
  }

  useEffect(() => {
    if (hidden || !duration) return
    const timeout = setTimeout(hide, duration)
    return () => clearTimeout(timeout)
  }, [duration, hidden])

  if (hidden) return null

  return (
    <div ref={wrapper} className="pds-display-inline-block pds-mt-xs">
      <Toast variant={variant}>
        {typeof content === 'function' ? content() : content}
      </Toast>
    </div>
  )
})

function show(options: ShowToastOptions) {
  return ref.current?.show(options)
}

export function notice(
  content: ToastContent,
  options: PartialShowOptions = {}
) {
  return show({ ...options, content, variant: 'notice' })
}

export function success(
  content: ToastContent,
  options: PartialShowOptions = {}
) {
  return show({ ...options, content, variant: 'success' })
}

export function danger(
  content: ToastContent,
  options: PartialShowOptions = {}
) {
  return show({ ...options, content, variant: 'danger' })
}

export function warning(
  content: ToastContent,
  options: PartialShowOptions = {}
) {
  return show({ ...options, content, variant: 'warning' })
}
