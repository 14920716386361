const adaOpenKey = 'chatDrawerOpen'

window.adaSettings ||= {}
window.adaSettings.toggleCallback = (isDrawerOpen) => {
  for (const element of document.querySelectorAll('._pendo-badge')) {
    element.hidden = isDrawerOpen
  }

  try {
    if (isDrawerOpen) {
      localStorage.setItem(adaOpenKey, 'true')
    } else {
      localStorage.removeItem(adaOpenKey)
    }
  } catch {}
}

// This is called from the Pendo resource center
// in the bottom right corner of harvestapp.
window.harvestOpenAda = async function () {
  await loadAdaScript()
}

/**
 * Open the Ada chat (if it was persisted)
 */
function openAdaIfNeeded() {
  try {
    const isChatDrawerOpen = localStorage.getItem(adaOpenKey)
    if (isChatDrawerOpen) window.harvestOpenAda()
  } catch {}
}

function loadAdaScript() {
  return new Promise((resolve) => {
    if (window.adaEmbed) {
      // If the script is already loaded, just call the toggle function
      window.adaEmbed.toggle()
      resolve()
    } else {
      const script = document.createElement('script')

      script.id = '__ada'
      script.dataset.handle = 'harvest-gen'
      script.src = 'https://static.ada.support/embed2.js'
      script.async = true
      script.onload = () => {
        window.adaEmbed.toggle()
        resolve()
      }

      document.body.appendChild(script)
    }
  })
}

document.addEventListener('DOMContentLoaded', () => {
  openAdaIfNeeded()
})
